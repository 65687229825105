import 'live-preview.png'; 
import each from "lodash/each"; 
import NormalizeWheel from "normalize-wheel"; 
 
import Canvas from "./components/canvas"; 
import Preloader from "./components/Preloader"; 
import Home from "./pages/Home"; 
import About from "./pages/About"; 
import Project from "./pages/Project"; 
import Navigation from './components/Navigation'; 
// import Cursor from './components/Cursor'; 
// import GSAP from 'gsap'; 
import Detection from './classes/Detection'; 
 
class App { 
	constructor() { 
		this.createContent(); 
 
		this.createCanvas(); 
		this.createPreloader(); 
		this.createNavigation(); 
		this.createPages(); 
 
		this.addEventListeners(); 
 
		this.addLinkListeners() 
 
		this.onResize(); 
 
		this.update(); 
 
		this.mouse = { 
			x: window.innerWidth / 2, 
			y: window.innerHeight / 2 
		} 
	} 
 
	createNavigation(){ 
		this.navigation = new Navigation({ 
			template: this.template, 
		}) 
	} 
	createPreloader() { 
		this.preloader = new Preloader({ 
			canvas: this.canvas 
		}); 
		this.preloader.once("completed", this.onPreloaded.bind(this)); 
	} 
 
	createCanvas() { 
		this.canvas = new Canvas({ 
			template: this.template, 
		}); 
	} 
 
	createContent() { 
		this.content = document.querySelector('.content'); 
		this.template = this.content.getAttribute('data-template'); 
	} 
 
	createPages() { 
		this.pages = { 
			home: new Home(), 
			about: new About(), 
			project: new Project(), 
		} 
 
		this.page = this.pages[this.template]; 
		this.page.create(); 
	} 
 
	/** 
	 * Events. 
	 */ 
 
	// Fires after everything is loaded 
	onPreloaded() { 
		// this.preloader.destroy(); 
 
		this.onResize(); 
 
		this.canvas.onPreloaded(); 
 
		this.page.show(); 
	} 
 
	onPopState() { 
		this.onChange({ 
			url: window.location.pathname, 
			push: true, 
		}); 
	} 
 
	async onChange({ url, push = true }) {  // location removed
 
		// Check if the URL is external or local 
		const isExternalLink = () => { 
			const tmp = document.createElement('a'); 
			tmp.href = url; 
			return tmp.host !== window.location.host; 
		}; 
		if (isExternalLink()){ 
			window.open(url, "_blank"); 
		} else { 
			this.canvas.onChangeStart(this.template, url); 
 
			await this.page.hide(); 
			const request = await window.fetch(url); 
 
			if (request.status === 200) { 
				const html = await request.text(); 
				const div = document.createElement("div"); 
 
				if (push) { 
					window.history.pushState({}, "", url); 
				} 
 
				div.innerHTML = html; 
 
				const divContent = div.querySelector(".content"); 
 
				this.template = divContent.getAttribute("data-template"); 
 
				this.navigation.onChange(this.template); 
 
				this.content.setAttribute("data-template", this.template); 
 
				this.content.innerHTML = divContent.innerHTML; 
 
				this.canvas.onChangeEnd(this.template) 
 
				this.page = this.pages[this.template]; 
 
				this.page.create(); 
 
 
				this.onResize(); 
 
				this.page.show(); 
 
				this.addLinkListeners(); 
			} else { 
				console.error(`response status: ${request.status}`); 
			} 
		} 
	} 
 
	onResize() { 
		if (this.page?.onResize) { 
			this.page.onResize(); 
		} 
 
		window.requestAnimationFrame(_ => { 
			if (this.canvas?.onResize) { 
				this.canvas.onResize(); 
			} 
		}) 
	} 
 
	onTouchDown(event) { 
		event.stopPropagation() 
 
		if (!Detection.isMobile() && event.target.tagName === 'A') return 
 
		this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX 
		this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY 
 
		if (this.page?.onTouchDown) { 
			this.page.onTouchDown(event) 
		} 
 
		if (this.canvas?.onTouchDown) { 
			this.canvas.onTouchDown(event); 
		} 
	} 
 
	onTouchMove(event) { 
		event.stopPropagation() 
 
		this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX 
		this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY 
 
		if (this.page?.onTouchMove) { 
			this.page.onTouchMove(event) 
		} 
 
		if (this.canvas?.onTouchMove) { 
			this.canvas.onTouchMove(event); 
		} 
	} 
 
	onTouchUp(event) { 
		event.stopPropagation() 
 
		this.mouse.x = event.changedTouches ? event.changedTouches[0].clientX : event.clientX 
		this.mouse.y = event.changedTouches ? event.changedTouches[0].clientY : event.clientY 
 
		if (this.page?.onTouchUp) { 
			this.page.onTouchUp(event) 
		} 
 
		if (this.canvas?.onTouchUp) { 
			this.canvas.onTouchUp(event); 
		} 
	} 
	onWheel(e) { 
		const normalizedWheel = NormalizeWheel(e); 
 
		if (this.canvas?.onWheel) { 
			this.canvas.onWheel(normalizedWheel); 
		} 
 
		if (this.page?.onWheel) { 
			this.page.onWheel(normalizedWheel); 
		} 
	} 
 
	/** 
	 * Loop. 
	 */ 
 
	update() { 
		if (this.page?.update) { 
			this.page.update(); 
		} 
 
		if (this.canvas?.update) { 
			this.canvas.update(this.page.scroll); 
		} 
 
		this.frame = window.requestAnimationFrame(this.update.bind(this)); 
	} 
 
 
 
	/** 
	 * Listeners. 
	 */ 
 
	addEventListeners() { 
		window.addEventListener('wheel', this.onWheel.bind(this)); 
 
		window.addEventListener("popstate", this.onPopState.bind(this)); 
 
 
		window.addEventListener('touchstart', this.onTouchDown.bind(this)); 
		window.addEventListener('touchmove', this.onTouchMove.bind(this)); 
		window.addEventListener('touchend', this.onTouchUp.bind(this)); 
 
		window.addEventListener("resize", this.onResize.bind(this)); 
	} 
 
	addLinkListeners() { 
		const links = document.querySelectorAll("a"); 
 
		each(links, (link) => { 
			link.onclick = (event) => { 
				event.preventDefault(); 
 
				const { href } = link; 
 
				this.onChange({ url: href }); 
			}; 
		}); 
	} 
 
} 
 
new App();