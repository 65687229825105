import Page from '../../classes/Page';
// import Lenis from '@studio-freight/lenis'
// import GSAP from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Home extends Page {
	constructor() {
		super({
			id: "home",
			
			element: ".home",
			elements: {
				wrapper: ".home-container",
				projects: ".projects-listing"
			},
		});
		
		// GSAP.registerPlugin(ScrollTrigger)
	}
	
	create() {
		super.create();

		// const lenis = new Lenis({
		// 	smooth: true,
		// 	infinite: true,
		// });
		//
		// function raf(time) {
		// 	lenis.raf(time);
		// 	requestAnimationFrame(raf);
		// }
		//
		// requestAnimationFrame(raf);
		//
		//
		// // repeat first six items by cloning them and appending them to the .grid
		// const repeatItems = (parentEl, total = 0) => {
		// 	const items = [...parentEl.children];
		// 	for (let i = 0; i <= total-1; ++i) {
		// 		var cln = items[i].cloneNode(true);
		// 		parentEl.appendChild(cln);
		// 	}
		// };
		//
		// repeatItems(document.querySelector('.projects-listing'), 10);
		//
		// lenis.on('scroll',()=>{
		// 	ScrollTrigger.update() // Thank you Clément!
		// })
	}

	show() {
		super.show();
	}

	destroy() {
		super.destroy();
	}
}