import GSAP from 'gsap';
import { Mesh, Plane, Program } from 'ogl';

import fragment from '../../shaders/image-fragment.glsl';
import vertex from '../../shaders/image-vertex.glsl';

export default class {
	constructor({gl, scene, sizes, url, media }) {
		this.gl = gl;
		this.scene = scene;
		this.sizes = sizes;
		this.url = url;
		this.media = media; // Add media 

		// this.isOpen = false;

		// this.geometry = new Plane(this.gl);
		this.geometry = new Plane(this.gl, {
			heightSegments: 50,
			widthSegments: 100
		})
		
	}

	createProgram(texture) {
		this.program = new Program(this.gl, {
			fragment,
			vertex,
			uniforms: {
				uAlpha: { value: 1 },
				tMap: { value: texture },
				uPlaneSizes: { value: [0, 0] },
				uImageSizes: { value: [0, 0] },
				uViewportSizes: { value: [this.sizes.width, this.sizes.height] },
				uMultiplier: { value: 1 },
				uSpeed: { value: 0 },
				uTime: { value: 0 },
			},
			// transparent: true, // Fliker case
		});
	}

	createMesh(mesh) {
		this.mesh = new Mesh(this.gl, {
			geometry: this.geometry,
			program: this.program,
		});

		this.mesh.scale.copy(mesh.scale);
        this.mesh.position.copy(mesh.position);
        this.mesh.position.z += 0.01;

		this.mesh.setParent(this.scene);
	}
	


	/**
	 * Element.
	 */
	setElement(element) {
		// Element here is the page itself Project or Home
		// this.element = element; // That was working properly

		if (element.id === 'home') {
			// From Home to Details
			const { index, medias } = element;
			const media = medias[index];
			
			this.createProgram(media.texture);
			this.createMesh(media.mesh);
			
			//this.isOpen = true; // That was working properly
			
			this.transition = 'project';
			
		} else {
			// From Details to Home
			this.createProgram(element.texture);
			this.createMesh(element.mesh);
			//this.isOpen = false; // That was working properly

			this.transition = 'home';
		}
		
		// Call the hide method on the media instance to remove the hover shader
        if (this.media) {
			this.media.isHoverDisabled = true;
			this.media.isHovering = false;
        }
	}

	/**
	 * Animations.
	 */
	animate(element, onComplete) {
		// Element here is the animated element itself
		
		const timeline = GSAP.timeline({});
		// From Home to Detail it will show the Detail element
		
		timeline.to(this.mesh.scale,{
			duration: 0.8, // 0.8
			ease: 'expo.inOut',
			x: element.scale.x,
			y: element.scale.y,
			z: element.scale.z,
		},0);

		timeline.to(this.mesh.position, {
			duration: 0.8, // 0.8
			ease: 'expo.inOut',
			x: element.position.x,
			y: element.position.y,
			z: element.position.z,
		},0);

		timeline.call((_) => {
			onComplete();
		});

		timeline.call((_) => {
			this.scene.removeChild(this.mesh);
			
			// this.media.enableHover();

		}, null, '+=0.2'); // 0.2
	}

	update(open) {
		this.program.uniforms.uTime.value += 0.1;
        this.program.uniforms.uSpeed.value += open ? 0.0095 : 0.0015;
        this.program.uniforms.uAlpha.value += 0.0035;
	}
}