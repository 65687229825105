import map from 'lodash/map';
import { Plane, Transform, Camera } from 'ogl';
import GSAP from 'gsap';
import Prefix from 'prefix';
import Media from './Media';
import { set } from 'lodash';

export default class Home {
	constructor({ gl, scene, sizes, screen, transition }) {
		this.id = 'home';

		this.gl = gl;
		this.sizes = sizes;
		this.scene = scene;
		this.screen = screen;
		this.transition = transition;

		this.transformPrefix = Prefix('transform');

		this.group = new Transform();

		this.scroll = {
			current: 0,
			start: 0,
			target: 0,
			lerp: 0.1,
			velocity: 1,
		};

		this.isOpen = false;

		this.galleryWrapperElement =
			document.querySelector('.projects-listing');
		this.home = document.querySelector('.home-container');
		// this.galleryElement = document.querySelector('.hover-reveal__img');
		// this.titlesElement = document.querySelector('.collections__titles')
		// this.collectionElements = document.querySelectorAll('.collections__article');
		// this.collectionElementsActive = 'project__article--active';

		this.createGeometry();
		this.createGallery();

		this.onResize({
			sizes: this.sizes,
		});

		this.camera = new Camera(this.gl);

		this.group.setParent(scene);

		this.show();
	}

	createGeometry() {
		this.planeGeometry = new Plane(this.gl, {
			heightSegments: 50,
			widthSegments: 100,
		});
	}

	createGallery() {
		this.mediasList = document.querySelector('.projects-listing');
		this.mediasElements = document.querySelectorAll('.project-details');

		this.medias = map(this.mediasElements, (element, index) => {
			const project = element.querySelector('.project-title');

			return new Media({
				element,
				geometry: this.planeGeometry,
				index,
				homeList: this.mediasList,
				gl: this.gl,
				scene: this.group,
				sizes: this.sizes,
				screen: this.screen,
				projectItem: project,
			});
		});
	}

	disableHoverOnAllMedias() {
        map(this.medias, (media) => {
            media.disableHover();
        });
    }

    enableHoverOnAllMedias() {
        map(this.medias, (media) => {
            media.enableHover();
        });
    }
	
	/**
	 * Animations
	 */

	async show() {		
		this.disableHoverOnAllMedias();

		if (this.transition) {
			// From Details to Home
			const { src } = this.transition.mesh.program.uniforms.tMap.value.image;
			const texture = window.TEXTURES[src];
			const media = this.medias.find((media) => media.texture === texture);
			const scrollMedia = -media.bounds.left - media.bounds.width / 2 + window.innerWidth / 2; // prettier-ignore

			this.update();

			this.isOpen = true;

			this.transition.update(this.isOpen);
			// media.onMouseOver(this.isOpen);
        
			// Triggered when going to the home from the details page
			this.transition.animate({
					position: {
						x: 0,
						y: media.mesh.position.y - media.mesh.scale.y * 0.5,
						z: 0,
					},
					rotation: media.mesh.rotation,
					scale: media.mesh.scale,
				}, (_) => {
					// media.opacity.multiplier = 1;
					map(this.medias, (item) => {
						if (media !== item) {
							item.show();
						}
					});

					this.scroll.current = this.scroll.target = this.scroll.start = this.scroll.last = scrollMedia; // prettier-ignore
					this.enableHoverOnAllMedias();

				}
			);
		} else {
			// on first load and when from about to home
			this.isOpen = false;
			map(this.medias, (media) => media.show());
			this.enableHoverOnAllMedias();
		}
	}

	hide() {
		map(this.medias, (media) => media.hide());
	}

	/**
	 * Events
	 */

	onResize(event) {
		this.sizes = event.sizes;

		this.bounds = this.home.getBoundingClientRect();

		this.scroll.last = this.scroll.target = 0; // Was comment

		// passing the scroll object to each media element
		map(this.medias, (media) => {
			media.onResize(this.sizes, this.scroll);
		});

		//FIXME: Triggers twice
		// Set scroll limits for the canvas
		if (this.bounds && this.bounds.height > window.innerHeight) {
			this.scroll.limit = this.bounds.height - window.innerHeight + this.bounds.top;
		}
	}

	// onTouchDown(){}

	// onTouchMove(){}

	// onTouchUp(){}

	onWheel({ pixelY }) {
		if (this.bounds && this.bounds.height > window.innerHeight) {
			this.scroll.target += pixelY;
		}
	}

	/**
	 * Changed
	 */

	// onChange (index){
	// 	this.index = index
	// }

	/**
	 * Update.
	 */

	update() {
		if (!this.bounds) return;

		this.scroll.target = GSAP.utils.clamp(0,
			this.scroll.limit,
			this.scroll.target
		);

		if (this.scroll.current < 0.01) {
			this.scroll.current = 0;
		}

		this.scroll.current = GSAP.utils.interpolate(
			this.scroll.current,
			this.scroll.target,
			this.scroll.lerp
		);

		// const scrollable = this.mediasList.clientHeight - window.innerHeight;
		this.scroll.last = this.scroll.current;

		// I'm passing the scroll coming from the canvas instead...
		// const y = this.scroll.current / window.innerHeight

		map(this.medias, (media, index) => {
			// I'm passing the scroll coming from the here...
			media.update(this.scroll, index);
		});

		// this.group.position.y = y * this.sizes.height;

		// console.log(this.group.position.y);
	}

	/**
	 * Destroy
	 */

	destroy() {
		this.scene.removeChild(this.group);
	}
}
