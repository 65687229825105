import { Camera, Renderer, Transform } from "ogl";

import Home from "./Home";
import Detail from "./Detail";
import Transition from "./Transition";

export default class Canvas {
	constructor({template}) {
		this.template = template;

		this.x = {
			start: 0,
			distance: 0,
			end: 0,
		}
		this.y = {
			start: 0,
			distance: 0,
			end: 0,
		}

		this.createRenderer();
		this.createCamera();
		this.createScene();

		this.onResize();
	}

	createRenderer() {
		this.renderer = new Renderer({
			alpha: true,
			antialias: true,
		});

		this.gl = this.renderer.gl;

		document.body.appendChild(this.gl.canvas);
	}

	createCamera() {
		this.camera = new Camera(this.gl);
		this.camera.position.z = 5;
		this.camera.fov = 45
	}

	createScene() {
		this.scene = new Transform();
	}


	/**
	 * Home
	 */

	createHome() {
		this.home = new Home({
			gl: this.gl,
			scene: this.scene,
			sizes: this.sizes,
			screen: this.screen,
			transition: this.transition,
			// FIXME: Enable
			// scroll: this.scroll
		})
	}

	destroyHome() {
		if (!this.home) return;

		this.home.destroy();
		this.home = null;
	}

	/**
	 * Detail
	 */

	createDetail() {
		this.detail = new Detail({
			gl: this.gl,
			scene: this.scene,
			sizes: this.sizes,
			transition: this.transition
		})
	}

	destroyDetail() {
		if (!this.detail) return;

		this.detail.destroy();
		this.detail = null;
	}

	/**
	 * Events
	 */

	onPreloaded(){
		this.onChangeEnd(this.template);
	}

	onChangeStart (template, url) {
		if (this.detail) {
			this.detail.hide();
		}

		if (this.home){
			this.home.hide();
		}
		
		if (url.indexOf('/project/') > -1) {
			this.home.medias.find(media => {
				if(media.element.href === url){
					this.home.index = media.index;
				}
			})
		}
		
		this.isFromHomeToDetail = template === 'home' && url.indexOf('project') > -1;
		this.isFromDetailToHome = template === 'project' && url.indexOf('home') === -1;
		
		if (this.isFromHomeToDetail || this.isFromDetailToHome) {
			this.transition = new Transition({
				gl: this.gl,
				scene: this.scene,
				sizes: this.sizes,
				url,
				media: this.isFromHomeToDetail ? this.home.medias[this.home.index] : this.detail.media
			});

			this.transition.setElement(this.home || this.detail);
		}
	}
	onChangeEnd(template){
		if(template === 'home'){
			this.createHome();
		} else {
			this.destroyHome();
		}

		if(template === 'project'){
			this.createDetail();
		} else if (this.detail) {
			this.destroyDetail();
		}

		this.template = template;
	}

	onResize() {
		this.screen = {
			height: window.innerHeight,
			width: window.innerWidth
		}

		this.renderer.setSize(this.screen.width, this.screen.height);

		this.camera.perspective({
			aspect: this.screen.width / this.screen.height,
		});

		const fov = this.camera.fov * (Math.PI / 180);
		const height = 2 * Math.tan(fov / 2) * this.camera.position.z;
		const width = height * this.camera.aspect;

		this.sizes = {
			height,
			width
		}

		const values = {
			sizes: this.sizes,
			scene: this.screen
		}
		
		if (this.detail){
			this.detail.onResize(values);
		}

		if (this.home) {
			this.home.onResize(values)
		}
	}


	onTouchDown(e) {
		this.isDown = true;

		this.x.start = e.touches ? e.touches[0].clientX : e.clientX;
		this.y.start = e.touches ? e.touches[0].clientY : e.clientY;

		const values = {
			x: this.x,
			y: this.y,
		};

		// if (this.detail) {
		// 	this.detail.onTouchDown(values);
		// }

		if (this.home) {
			this.home.onTouchDown(values);
		}
	}

	onTouchMove(e) {
		if (!this.isDown) return;

		const x = e.touches ? e.touches[0].clientX : e.clientX;
		const y = e.touches ? e.touches[0].clientY : e.clientY;

		this.x.end = x;
		this.y.end = y;

		const values = {
			x: this.x,
			y: this.y,
		};

		// if (this.detail) {
		// 	this.detail.onTouchMove(values);
		// }

		if (this.home) {
			this.home.onTouchMove(values);
		}
	}

	onTouchUp(e) {
		this.isDown = false;

		const x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
		const y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

		
		this.x.end = x;
		this.y.end = y;

		const values = {
			x: this.x,
			y: this.y,
		};

		// if (this.detail) {
		// 	this.detail.onTouchUp(values);
		// }

		if (this.home) {
			this.home.onTouchUp(values);
		}
	}

	onWheel(event){
		if (this.home) {
			this.home.onWheel(event)
		}
	}

	/**
	 * Loop
	 */
	update(scroll) {
		this.scroll = scroll;

		// Working?
		if (this.transition){
			this.transition.update();
		}

		if (this.detail){
			this.detail.update(scroll);
		}

		if(this.home){
			// TODO: I'm not depending on the scroll from here..
			// this.home.update(scroll);
			this.home.update();
		}

		this.renderer.render({
			camera: this.camera,
			scene: this.scene,
		});
	}
}