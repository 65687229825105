import GSAP from 'gsap';
import { Mesh, Plane, Program } from 'ogl';

import fragment from '../../../shaders/image-fragment.glsl';
import vertex from '../../../shaders/image-vertex.glsl';

export default class {
	constructor({ gl, scene, sizes, transition }) {
		this.id = 'project';

		this.element = document.querySelector('.project-img');

		this.gl = gl;
		this.scene = scene;
		this.sizes = sizes;
		this.transition = transition;

		// this.geometry = new Plane(this.gl);
		this.geometry = new Plane(this.gl, {
			heightSegments: 50,
			widthSegments: 100,
		})
		
		this.createTexture();
		this.createProgram();
		this.createMesh();
		this.createBounds({
			sizes: this.sizes,
		});

		this.show();
	}

	createTexture() {
		const image = this.element.getAttribute('data-src');

		this.texture = window.TEXTURES[image];
	}

	createProgram() {
		this.program = new Program(this.gl, {
			fragment,
			vertex,
			uniforms: {
				uAlpha: { value: 0 }, // Right value is 0
				tMap: { value: this.texture },
				uPlaneSizes: { value: [0, 0] },
				uImageSizes: { value: [0, 0] },
				uViewportSizes: { value: [this.sizes.width, this.sizes.height] },
				uMultiplier: { value: 1 },
				uSpeed: { value: 0 },
				uTime: { value: 0 },
			},
			transparent: true,
		});
	}

	createMesh() {
		this.mesh = new Mesh(this.gl, {
			geometry: this.geometry,
			program: this.program,
		});

		this.mesh.setParent(this.scene);
	}

	createBounds({ sizes }) {
		this.sizes = sizes;

		this.bounds = this.element.parentElement.getBoundingClientRect();

		this.updateScale();
		this.updateX();
		this.updateY();
	}

	/**
	 * Animations.
	 */
	show() {
		// From Home to Details
		if (this.transition) {
			this.transition.animate(this.mesh, (_) => {
				this.program.uniforms.uAlpha.value = 1; // Right value is 1
			});
		} else {
			GSAP.to(this.program.uniforms.uAlpha, {
				value: 1, // Right value is 1
			});
		}
	}

	hide() {
		GSAP.to(this.program.uniforms.uAlpha, {
			value: 0, // Right value is 1
		})
	}

	/**
	 * Events.
	 */
	onResize(sizes) {
		this.createBounds(sizes);
		this.updateX();
		this.updateY();
	}

	/**
	 * Loop.
	 */

	updateScale() {
		this.height = this.bounds.height / window.innerHeight;
		this.width = this.bounds.width / window.innerWidth;
		
		this.mesh.scale.x = this.sizes.width * this.width;
		this.mesh.scale.y = this.sizes.height * this.height;
	}

	updateX() {
		this.x = this.bounds.left / window.innerWidth;

		this.mesh.position.x =
			-this.sizes.width / 2 + this.mesh.scale.x / 2 + this.x * this.sizes.width;
	}

	updateY() {
		this.y = this.bounds.top / window.innerHeight;
		
		this.mesh.position.y =
			this.sizes.height / 2 -
			this.mesh.scale.y / 2 -
			-this.y * this.sizes.height + 0.01;
		
	}

	update(scroll) {
		if (scroll.current < 0.01) {
			scroll.current = 0;
		}
		
		const y = scroll.current / window.innerHeight;

		this.mesh.position.y =
			this.sizes.height / 2 -
			this.mesh.scale.y / 2 -
			-y * this.sizes.height + 0.01;
	}

	/**
	 * Destroy.
	 */
	destroy() {
		this.scene.removeChild(this.mesh);
	}
}