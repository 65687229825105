import GSAP from "gsap";

import Animation from "../classes/Animation";

import each from "lodash/each";
import { calculate, split } from "../utils/text";

export default class Home extends Animation {
	constructor({ element, elements }) {
		super({ element, elements });
		
		this.elementLinesSpans = split({
			append: true,
			element: this.element,
		});
	}

	animateIn() {
		this.timelineIn = GSAP.timeline();

		this.timelineIn.set(this.element, {
			autoAlpha: 1,
		});
		
		each(this.elementLinesSpans, (line, index) => {
			this.timelineIn.fromTo( line,
				{
					autoAlpha: 0,
					y: "100%",
				},
				{
					autoAlpha: 1,
					stagger: 0.03,
					delay: index * 0.02,
					duration: 1,
					ease: "expo.out",
					y: "0%",
				},
				0.5
			);
		});
	}

	animateOut() {
		GSAP.set(this.element, {
			autoAlpha: 0,
		});
	}

	onResize() {
		this.elementLines = calculate(this.elementLinesSpans);
	}
}
