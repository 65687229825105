// Map number x from range [a, b] to [c, d]
// const imagesLoaded = require('imagesloaded'); // "imagesloaded": "^4.1.4", 

export const mouseMap = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

export const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num;

// Linear interpolation
export const lerpMouse = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position
export const getMousePos = e => {
	return {
		x : e.clientX,
		y : e.clientY
	};
};

// Preload images
// export  const preloadImages = (selector = 'img') => {
// 	return new Promise((resolve) => {
// 		imagesLoaded(document.querySelectorAll(selector), {background: true}, resolve);
// 	});
// };
//
// // Preload images
// export const preloadFonts = (id) => {
// 	return new Promise((resolve) => {
// 		WebFont.load({
// 			typekit: {
// 				id: id
// 			},
// 			active: resolve
// 		});
// 	});
// };

