import GSAP from 'gsap';
import Prefix from 'prefix';
import { each, map } from 'lodash';
import AsyncLoad from './AsyncLoad';
import Title from '../animations/Title';
import Paragraph from '../animations/Paragraph';
import Label from '../animations/Label';
import Home from '../animations/Home';
// import Preview from '../components/Preview';
import { ColorsManager } from './Colors';
import Cursor from '../components/Cursor';
// import MenuController from '../animations/Controller';
// import Detection from './Detection';

export default class Page {
	constructor({ element, elements, id }) {
		this.selector = element;
		this.selectorChildren = {
			...elements,
			animationsTitles: '[data-animation="title"]',
			animationsParagraphs: '[data-animation="paragraph"]',
			animationsLabels: '[data-animation="label"]',
			animationsProject: '[data-animation="project"]',

			preloaders: '[data-src]',
		};

		this.id = id;

		this.transformPrefix = Prefix('transform');
	}

	create() {
		this.element = document.querySelector(this.selector);
		this.elements = {};

		this.scroll = {
			current: 0,
			target: 0,
			last: 0,
			limit: 0,
			lerp: 0.1,
			velocity: 0.5,
		};

		each(this.selectorChildren, (entry, key) => {
			if (
				entry instanceof window.HTMLElement ||
				entry instanceof window.NodeList ||
				Array.isArray(entry)
			) {
				this.elements[key] = entry;
			} else {
				this.elements[key] = document.querySelectorAll(entry);

				if (this.elements[key].length === 0) {
					this.elements[key] = null;
				} else if (this.elements[key].length === 1) {
					this.elements[key] = document.querySelector(entry);
				}
			}
			// console.log(this.elements[key], entry);
		});

		this.createCursor();
		this.createPreloader();
		this.createAnimations();
	}

	// Lazy load all images
	createPreloader() {
		this.preloaders = map(this.elements.preloaders, (element) => {
			return new AsyncLoad({ element });
		});
	}

	createCursor() {
		const cursor = new Cursor(document.querySelector('.cursor'));
		[...document.querySelectorAll('a')].forEach((link) => {
			link.addEventListener('mouseenter', () => cursor.enter());
			link.addEventListener('mouseleave', () => cursor.leave());
		});
	}

	createAnimations() {
		this.animations = [];

		this.animationsTitles = map(
			this.elements.animationsTitles,
			(element) => {
				return new Title({ element });
			}
		);

		this.animations.push(...this.animationsTitles);

		this.animationsParagraphs = map(
			this.elements.animationsParagraphs,
			(element) => {
				return new Paragraph({ element });
			}
		);

		this.animations.push(...this.animationsParagraphs);

		this.animationsLabels = map(
			this.elements.animationsLabels,
			(element) => {
				return new Label({ element });
			}
		);

		this.animations.push(...this.animationsLabels);

		this.animationsProject = map(
			this.elements.animationsProject,
			(element) => {
				return new Home({ element });
			}
		);

		this.animations.push(...this.animationsProject);

		// this.animations.once("completed", this.triggerAnimations.bind(this));

		// console.log(this.animations.once(), "Show");
		// this.animations.once("animateInCompleted", this.onAnimateInCompleted.bind(this));
	}

	// onAnimateInCompleted() {}

	// triggerAnimations() {
	// 	this.createAnimations();
	// }

	/**
	 * Animations
	 */
	show(animation) {
		return new Promise((resolve) => {
			ColorsManager.change({
				backgroundColor: this.element.getAttribute('data-background'),
				color: this.element.getAttribute('data-color'),
			});

			if (animation) {
				this.animationIn = animation;
			} else {
				this.animationIn = GSAP.timeline();
				this.animationIn.fromTo(
					this.element,
					{
						autoAlpha: 0,
					},
					{
						autoAlpha: 1,
					}
				);
			}

			// this.element
			// console.log(this.element.getAttribute('class'), "What is the element?")

			// When the animation completes this is called
			this.animationIn.call((_) => {
				// this.addEventListeners();

				// Hove effect without WebGL support
				// if (this.id === "home"){
				// 	new MenuController(document.querySelector('.projects-listing'));
				// }

				// if (this.id === "home"){
				// 	new MenuController(document.querySelector('.projects-listing'));
				// }
				resolve();

				document.querySelector('html').style.pointerEvents = 'all';
			});
		});
	}

	hide() {
		return new Promise((resolve) => {
			this.destroy();
			this.animationIn = GSAP.timeline();

			this.animationIn.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});

			document.querySelector('html').style.pointerEvents = 'none';
		});
	}

	/**
	 * Events
	 */

	onResize() {
		// TODO: Why I didn't use the below check
		if (
			this.elements.wrapper &&
			this.elements.wrapper.clientHeight > window.innerHeight
		) {
			this.scroll.limit =
				this.elements.wrapper.clientHeight - window.innerHeight;
		}

		each(this.animations, (animation) => animation.onResize());
	}

	onTouchDown(event) {
		this.isDown = true;

		this.scroll.position = this.scroll.current;
		this.start = event.touches ? event.touches[0].clientY : event.clientY;
	}

	onTouchMove(event) {
		if (!this.isDown) {
			return;
		}

		const y = event.touches ? event.touches[0].clientY : event.clientY;
		const distance = (this.start - y) * 2;

		this.scroll.target = this.scroll.position + distance;
	}

	onTouchUp() {
		this.isDown = false;
	}

	onWheel({ pixelY }) {
		if (
			this.elements.wrapper &&
			this.elements.wrapper.clientHeight > window.innerHeight
		) {
			this.scroll.target += pixelY;
		}
	}

	/**
	 * Loops
	 */

	update() {
		/// Page Scrolling
		this.scroll.target = GSAP.utils.clamp(
			0,
			this.scroll.limit,
			this.scroll.target
		);

		if (this.scroll.current < 0.01) {
			this.scroll.current = 0;
		}

		this.scroll.current = GSAP.utils.interpolate(
			this.scroll.current,
			this.scroll.target,
			this.scroll.lerp
		);

		if (this.elements.wrapper) {
			this.elements.wrapper.style[
				this.transformPrefix
			] = `translateY(-${this.scroll.current}px)`;
		}
	}

	/**
	 * Listeners
	 */

	addEventListeners() {}

	removeEventListeners() {}

	/**
	 * Destroy
	 */
	destroy() {
		this.removeEventListeners();
	}
}
