import GSAP from 'gsap';

// import { COLOR_MINE_SHAFT, COLOR_COD_GRAY, COLOR_WHITE } from '../utils/color';
import Component from '../classes/Component';

export default class Navigation extends Component {
	constructor({ template }) {
		super({
			element: '#main-navigation',
			elements: {
				items: '.navigation-item',
				links: 'navigation-item-link',
			},
		});

		this.onChange(template);
	}

	onChange(template) {
		if (template === 'about' || template === 'project') {
			GSAP.to(this.element, {
				// color: COLOR_WHITE,
				duration: 1.5,
			});

			GSAP.to(this.elements.items[0], {
				autoAlpha: 1,
				delay: 0.75,
				duration: 0.75,
			});

			GSAP.to(this.elements.items[1], {
				autoAlpha: 0,
				duration: 0.75,
			});
		} else {
			GSAP.to(this.element, {
				// color: COLOR_WHITE,
				duration: 1.5,
			});

			GSAP.to(this.elements.items[0], {
				autoAlpha: 0,
				duration: 0.75,
			});

			GSAP.to(this.elements.items[1], {
				autoAlpha: 1,
				delay: 0.75,
				duration: 0.75,
			});
		}
	}
}
