import Page from '../../classes/Page';

export default class Home extends Page {
	constructor() {
		super({
			id: "about",
			
			element: ".about",
			elements: {
				wrapper: ".about-container",
			},
		});
	}

	create() {
		super.create();
	}

	destroy() {
		super.destroy();
	}

}