import { Program, Mesh } from 'ogl';
import GSAP from "gsap";

import Detection from 'app/classes/Detection';
import { getOffset } from 'app/utils/dom'
import fragment from "../../../shaders/image-fragment.glsl";
import vertex from "../../../shaders/image-vertex.glsl";
import { lerpMouse, clamp, getMousePos } from '../../../utils/Mouse';

export default class Media {
	constructor({element,  geometry, homeList, gl, projectItem, scene, index, sizes, screen}) {
		this.element = element;
		this.geometry = geometry;
		this.gl = gl;
		this.index = index;
		this.scene = scene;
		this.sizes = sizes;
		this.screen = screen;
		
		// this.createPlane();
		this.homeList = homeList
		this.project = projectItem

		this.transition = 0

		// Track the mouse position
		this.mousepos = {x: 0, y: 0};
		// Cache the mouse position
		this.mousePosCache = this.mousepos;

		this.direction = {x: this.mousePosCache.x-this.mousepos.x, y: this.mousePosCache.y-this.mousepos.y};

		this.animatableProperties = {
			// translationX
			tx: {previous: 0, current: 0, amt: 0.08},
			// translationY
			ty: {previous: 0, current: 0, amt: 0.08},
			// Rotation angle
			rotation: {previous: 0, current: 0, amt: 0.05}
		};

		this.extra = {x: 0,y: 0};

		this.opacity = {
			current: 0,
			target: 0,
			lerp: 0.1,
			multiplier: 0
		}

		this.createTexture();
		this.createProgram();
		this.createMesh();
		this.createBounds({sizes: this.sizes});
		this.createListeners()

		window.addEventListener('mousemove', ev => this.mousepos = getMousePos(ev));
	}

	createTexture() {
		this.image = this.element.querySelector('.project-gallery-media-image')
		this.source = this.image.getAttribute('data-src');
		this.texture = window.TEXTURES[this.source];
	}

	createProgram() {
		this.program = new Program(this.gl,{
			fragment,
			vertex,
			uniforms: {
				uAlpha: { value: 0 },
				tMap: { value: this.texture },
				uPlaneSizes: { value: [0, 0] },
				uImageSizes: { value: [0, 0] },
				uViewportSizes: { value: [this.sizes.width, this.sizes.height] },
				uMultiplier: { value: 1 },
				uSpeed: { value: 0 },
				uTime: { value: 0 },
			},
			// transparent: true,
		})
	}


	createMesh() {
		this.mesh = new Mesh(this.gl,{
			geometry: this.geometry,
			program: this.program,
		})
		this.mesh.visible = false; // Hide the mesh
		this.mesh.setParent(this.scene);
	}

	createBounds (sizes){
		this.boundsList = getOffset(this.homeList)
		this.boundsItem = getOffset(this.project)
		// this.boundsCase = getOffset(this.element, this.scroll) // it was like this before
		this.boundsCase = getOffset(this.element)

		this.bounds = this.image.getBoundingClientRect();
		// this.el = this.element.getBoundingClientRect();
		// this.projectEl = this.project.getBoundingClientRect();

		
		this.updateScale(sizes);
		
		// this.updateX.bind(this); // past implementation
		// this.updateY.bind(this); // Remove Y 
		// this.updateX();
		// this.updateY();
	}

	createListeners () {
		if (Detection.isMobile()) {
			this.element.addEventListener('touchstart', this.onMouseOver, { passive: true })

			window.addEventListener('touchend', this.onMouseLeave, { passive: true })
		} else {
			this.element.addEventListener('mouseover', this.onMouseOver.bind(this))
			this.element.addEventListener('mouseout', this.onMouseLeave.bind(this))
		}
	}


	/**
	 * Animations
	 */

	show() {
		GSAP.to(this.program.uniforms.uAlpha, {
			value: 1, // The correct value is 0
		})
	}

	hide() {
		GSAP.to(this.program.uniforms.uAlpha, {
			value: 0,
		})
	}


	/**
	 * Events.
	 */
	onResize(sizes, scroll) {
		this.createBounds(sizes);
		
		// FIXME: There is no scroll X or Y position
		this.updateScale()
		
		// No need to pass the scroll as the first onResize there won't be any..
		this.updateX();
		this.updateY(scroll?.current);
	}


	/**
	 * Loop.
	 */

	updateY(y = 0){
		this.y = (this.bounds.top + y) / window.innerHeight;

		// FIXME: In Mobile the position of the mesh is wrong, I think I need to re-visit this later
		if (Detection.isMobile() && window.innerWidth < 768) {
			this.mesh.position.y = (this.sizes.height / 2) - (this.mesh.scale.y / 2) - (this.y * this.sizes.height) + this.extra.y;
			this.mesh.position.y = this.mesh.position.y + this.mesh.scale.y
		} else{
			this.mesh.position.y = (this.sizes.height / 2) - (this.mesh.scale.y / 2) - (this.y * this.sizes.height) + this.extra.y + (this.mesh.scale.y * 0.05);
			// this.mesh.position.y = this.mesh.position.y + (this.mesh.scale.y * 0.05) //  / 2.25
		}
	}

	updateScale (){
		this.height = this.bounds.height / window.innerHeight;
		this.width = this.bounds.width / window.innerWidth;

		if (Detection.isMobile() || window.innerWidth < 768) {
			this.mesh.scale.x = this.sizes.width * this.width * 2.35;
			this.mesh.scale.y = this.sizes.height * this.height * 2.35;
		} else {
			this.mesh.scale.x = this.sizes.width * this.width;
			this.mesh.scale.y = this.sizes.height * this.height;
		}
	}

	updateX(x = 0){
		this.x = (this.bounds.left + x) / window.innerWidth;

		if (Detection.isMobile() || window.innerWidth < 768) {
			this.mesh.position.x = 0
		} else{
			this.mesh.position.x = (-this.sizes.width / 2) + (this.mesh.scale.x / 2) + (this.x * this.sizes.width) + this.extra.x;
		}
	}
	
	renderImage (){
		this.requestId = undefined;

		// if ( this.firstRAFCycle ) {
		// 	// calculate position/sizes the first time
		// 	this.createBounds(this.sizes);
		// }

		
		// calculate the mouse distance (current vs previous cycle)
		this.mouseDistanceX = clamp(Math.abs(this.mousePosCache.x - this.mousepos.x), 0, 100);
		// direction where the mouse is moving
		this.direction = {x: this.mousePosCache.x-this.mousepos.x, y: this.mousePosCache.y-this.mousepos.y};
		// updated cache values
		this.mousePosCache = {x: this.mousepos.x, y: this.mousepos.y};

		// new translation values
		this.animatableProperties.tx.current = Math.abs(this.mousepos.x - this.mesh.position.x) - this.bounds.width * 2.5; // 10 // 2.8
		// this.animatableProperties.ty.current = Math.abs(this.mousepos.y + this.mesh.position.y);

		// set up the interpolated values
		// for the first cycle, both the interpolated values need to be the same so there's no "lerped" animation between the previous and current state
		this.animatableProperties.tx.previous = this.firstRAFCycle ? this.animatableProperties.tx.current : lerpMouse(this.animatableProperties.tx.previous, this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
		// this.animatableProperties.ty.previous = this.firstRAFCycle ? this.animatableProperties.ty.current : lerpMouse(this.animatableProperties.ty.previous, this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
		// this.animatableProperties.rotation.previous = this.firstRAFCycle ? this.animatableProperties.rotation.current : lerpMouse(this.animatableProperties.rotation.previous, this.animatableProperties.rotation.current, this.animatableProperties.rotation.amt);

		
		this.mouseAnimate = {
			// currentX: this.animatableProperties.tx.current,
			// currentY: this.animatableProperties.ty.current,
			// previousX: this.animatableProperties.tx.previous,
			// previousY: this.animatableProperties.ty.previous,
			x: this.animatableProperties.tx.previous * 0.001,
			// Needs improvments to control the position y based on the mouse movement
			// y: (-this.animatableProperties.ty.previous * 0.001) + 1.35,
			// y: this.mesh.position.y + 0.315,
			// scrollY: positionY
			// Forget about the rotation it doesn't look good
			// Check the initial position of the mesh
		}

		if(this.isHovering && window.innerWidth > 768){
			this.showImage()
			// this.mesh.position.y = (this.sizes.height / 2 - this.mesh.scale.y / 2 - this.y * this.sizes.height) + 0.4;
		} else {
			this.hideImage();
		}

		// loop
		this.firstRAFCycle = false;
	}

	showImage(){
		this.firstRAFCycle = true;
		this.mesh.visible = true;
		
		// kill any current tweens
		GSAP.killTweensOf(this.mesh.program.uniforms.uAlpha);
		GSAP.killTweensOf(this.mesh.scale);
		GSAP.killTweensOf(this.mesh.position);
		
		GSAP.timeline()

		// FIXME: Opacity not working properly
		GSAP.to(this.mesh.program.uniforms.uAlpha, {
			value: 0.8,
			duration: 0.8,
			ease: 'quint',
		}) // Not working
		
		if (Detection.isMobile() && window.innerWidth < 768) {
			GSAP.to(this.mesh.scale, {
				x: 1.5,
				y: 0.75,
				z: 0.75,
				transformOrigin:"50% 50% 0",
				duration: 0.8,
				ease: 'quint',
			})
		} else {
			// TODO: Update the scale to be bigger
			GSAP.to(this.mesh.scale, {
				x: 2.35,
				y: 1,
				z: 1,
				transformOrigin:"50% 50% 0",
				duration: 0.8,
				ease: 'quint',
			})
			// this.mesh.position.y = this.mesh.position.y - this.mesh.scale.y * 2
			// Was Working
			// console.log(this.mesh.position, "position?");
			GSAP.to(this.mesh.position, {
				x: this.mouseAnimate.x,
				// y: 0, // 5
				duration: 0.8,
				ease: 'quint',
			})
		}
	}

	hideImage(){
		return new Promise(resolve => {
			// kill any current tweens
			GSAP.killTweensOf(this.mesh.scale);
			GSAP.killTweensOf(this.mesh.position);
			GSAP.killTweensOf(this.program.uniforms.uAlpha);

			GSAP.timeline({
				onComplete: () => {
					this.mesh.visible = false
					// this.program.uniforms.uAlpha.value = 0
					resolve();
					// this.stopRendering();
				}
			})
			if (!Detection.isMobile()) {
				GSAP.to(this.mesh.scale, {
					x: 0,
					y: 0,
					duration: 0.8,
					ease: 'quint',
				})
			}
			GSAP.to(this.mesh.position, {
				x: 0,
				y: 0,
				duration: 0.8,
				ease: 'quint',
			})
			GSAP.to(this.program.uniforms.uAlpha, {
				value: 0,
				duration: 0.8,
				ease: 'quint',
			})
		});
	}

	update(scroll){
		if (!this.bounds) return;
	
		// Now I have the scroll object where I can set limit to mesh scroll
		this.scroll = scroll;
		
		this.updateY(-this.scroll.current);
		
		if ( !this.requestId && this.isHovering ) {
			this.requestId = requestAnimationFrame(() => this.renderImage());
		}
		
		this.program.uniforms.uTime.value += 0.09
	}

	onMouseOver () {
        if (this.isHoverDisabled) return; // Disable hover effect if isHoverDisabled is true
		this.isHovering = true;
	}

	onMouseLeave () {
        if (this.isHoverDisabled) return; // Disable hover effect if isHoverDisabled is true
		// if (this.isHoverDisabled) return; // Disable hover effect if isOpen is true
		this.isHovering = false
	}
	
	disableHover() {
        this.isHoverDisabled = true;
    }

    enableHover() {
        this.isHoverDisabled = false;
    }
}