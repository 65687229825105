import Page from '../../classes/Page';
import GSAP from 'gsap';

export default class Project extends Page {
	constructor() {
		super({
			id: 'project',

			element: '.project',
			elements: {
				wrapper: '.project-container',
				preview: '.live-preview img',
			},
		});
	}

	create() {
		super.create();

		// Animate Live Preview
		this.livePreview = GSAP.to(this.elements.preview, {
			rotation: '360',
			duration: 4,
			ease: 'none',
			repeat: -1,
		});
	}

	onEnter() {
		// On Mouse Enter reduce the speed
		GSAP.to(this.livePreview, {
			timeScale: 0.2,
			duration: 1,
		});
	}

	onLeave() {
		// On Mouse leave increase the speed
		GSAP.to(this.livePreview, {
			timeScale: 1,
			duration: 1,
		});
	}

	addEventListeners() {
		if (this.element.preview) {
			this.elements.preview.addEventListener(
				'mouseenter',
				this.onEnter.bind(this)
			);
			this.elements.preview.addEventListener(
				'mouseleave',
				this.onLeave.bind(this)
			);
		}
	}

	destroy() {
		super.destroy();
	}
}
