import GSAP  from 'gsap';
import { lerpMouse, getMousePos } from '../utils/Mouse';
//
// // Track the mouse position
// let mouse = {x: 0, y: 0};
// window.addEventListener('mousemove', ev => mouse = getMousePos(ev));
export default class Cursor {
	constructor(el) {
		this.DOM = {el};
		this.DOM.el.style.opacity = 0;

		this.bounds = this.DOM.el.getBoundingClientRect();
		this.mouse = {x: 0, y: 0};
		window.addEventListener('mousemove', ev => this.mouse = getMousePos(ev));
		
		this.renderedStyles = {
			tx: {previous: 0, current: 0, amt: 0.2},
			ty: {previous: 0, current: 0, amt: 0.2},
			scale: {previous: 1, current: 1, amt: 0.2},
			opacity: {previous: 1, current: 1, amt: 0.2}
		};

		this.onMouseMoveEv = () => {
			this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.bounds.width/2;
			this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = this.mouse.y - this.bounds.height/2;
			GSAP.to(this.DOM.el, {duration: 0.9, ease: 'Power3.easeOut', opacity: 1});
			requestAnimationFrame(() => this.render());
			window.removeEventListener('mousemove', this.onMouseMoveEv);
		};
		window.addEventListener('mousemove', this.onMouseMoveEv);
	}
	enter() {
		this.renderedStyles.scale.current = 2.2;
		this.renderedStyles.opacity.current = 1;
	}
	leave() {
		this.renderedStyles.scale.current = 1;
		this.renderedStyles.opacity.current = 0.8;
	}
	render() {
		this.renderedStyles.tx.current = this.mouse.x - this.bounds.width/2;
		this.renderedStyles.ty.current = this.mouse.y - this.bounds.height/2;

		for (const key in this.renderedStyles ) {
			this.renderedStyles[key].previous = lerpMouse(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
		}

		this.DOM.el.style.transform = `translateX(${(this.renderedStyles.tx.previous)}px) translateY(${this.renderedStyles.ty.previous}px) scale(${this.renderedStyles.scale.previous})`;
		this.DOM.el.style.opacity = this.renderedStyles.opacity.previous;

		requestAnimationFrame(() => this.render());
	}
}